/* https://coolors.co/ffffff-f2f8ff-004682-ff0035-433e0e */

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;600&display=swap');

body {
  background-color: #F2F8FF;
  margin: 0;
  padding: 0;
  font-family: 'Quicksand', sans-serif;
  color: #333333;
}

* {
  box-sizing: border-box;
}
